<template>
  <div class="box-500">
    <p class="info">Oh No! Cody is sad! Internally sad!</p>
    <h1 class="internal">
      <span class="five">5</span><span class="zero">0</span><span class="zero">0</span>
    </h1>
    <div class="instructions">
      <p>In the meantime, you can:</p>
      <div class="step">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.711 489.711">
          <path
            d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13    c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13    c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1    c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"
          ></path>
          <path
            d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7    c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8    c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1    c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4    C456.056,357.911,481.656,274.811,462.456,195.511z"
          ></path>
        </svg>
        <p>Refresh the page</p>
      </div>
      <div class="step">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
          <path
            d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30   S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
          ></path>
          <path
            d="M30,6c-0.552,0-1,0.447-1,1v23H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1V7C31,6.447,30.552,6,30,6z"
          ></path>
        </svg>
        <p>Wait a few minutes</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700');
@-webkit-keyframes cross1 {
  0% {
    transform: rotate(45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
  }
  100% {
    transform: rotate(45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  }
}
@keyframes cross1 {
  0% {
    transform: rotate(45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
  }
  100% {
    transform: rotate(45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  }
}
@-webkit-keyframes cross2 {
  0% {
    transform: rotate(-45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
  }
  100% {
    transform: rotate(-45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  }
}
@keyframes cross2 {
  0% {
    transform: rotate(-45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
  }
  100% {
    transform: rotate(-45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  }
}
@-webkit-keyframes cross1Reverse {
  100% {
    transform: rotate(45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
    opacity: 0;
  }
  0% {
    transform: rotate(45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
@keyframes cross1Reverse {
  100% {
    transform: rotate(45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
    opacity: 0;
  }
  0% {
    transform: rotate(45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
@-webkit-keyframes cross2Reverse {
  100% {
    transform: rotate(-45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
    opacity: 0;
  }
  0% {
    transform: rotate(-45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
@keyframes cross2Reverse {
  100% {
    transform: rotate(-45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
    opacity: 0;
  }
  0% {
    transform: rotate(-45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
@-webkit-keyframes flip {
  0% {
    transform: rotate(-90deg) rotateY(0deg) translateX(0);
  }
  60% {
    transform: rotate(-90deg) rotateY(200deg) translateX(3vmin);
  }
  80% {
    transform: rotate(-90deg) rotateY(170deg) translateX(3vmin);
  }
  100% {
    transform: rotate(-90deg) rotateY(180deg) translateX(3vmin);
  }
}
@keyframes flip {
  0% {
    transform: rotate(-90deg) rotateY(0deg) translateX(0);
  }
  60% {
    transform: rotate(-90deg) rotateY(200deg) translateX(3vmin);
  }
  80% {
    transform: rotate(-90deg) rotateY(170deg) translateX(3vmin);
  }
  100% {
    transform: rotate(-90deg) rotateY(180deg) translateX(3vmin);
  }
}
@-webkit-keyframes flipReverse {
  100% {
    transform: rotate(-90deg) rotateY(0deg) translateX(0);
  }
  0% {
    transform: rotate(-90deg) rotateY(180deg) translateX(3vmin);
  }
}
@keyframes flipReverse {
  100% {
    transform: rotate(-90deg) rotateY(0deg) translateX(0);
  }
  0% {
    transform: rotate(-90deg) rotateY(180deg) translateX(3vmin);
  }
}
.box-500 {
  width: 100vw;
  height: 100vh;
  background-color: #009750;
  overflow: hidden;
  background-image: linear-gradient(45deg, #009750, #8dc63f);
  font-family: 'Open Sans';
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 1050;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.internal {
  font-size: 25vmin;
  text-align: center;
  color: #fff;
  text-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  position: relative;
  margin-bottom: 5vmin;
  transition: transform 300ms;
}
.internal:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.internal:hover:before {
  -webkit-animation: flipReverse 300ms;
  animation: flipReverse 300ms;
}
.internal:hover .zero:before {
  -webkit-animation: cross1Reverse 300ms;
  animation: cross1Reverse 300ms;
}
.internal:hover .zero:after {
  -webkit-animation: cross2Reverse 300ms;
  animation: cross2Reverse 300ms;
}
.internal:before {
  content: '(';
  position: absolute;
  transform: rotate(-90deg);
  right: 13vmin;
  bottom: -13vmin;
  display: block;
  font-size: 95%;
  -webkit-animation: flip 1000ms 1.6s ease-in-out forwards;
  animation: flip 1000ms 1.6s ease-in-out forwards;
  transition: transform 300ms;
}

.zero {
  position: relative;
}
.zero:before,
.zero:after {
  position: absolute;
  display: block;
  content: '';
  width: 130%;
  height: 5vmin;
  background: #8dc63f;
  background-image: linear-gradient(90deg, #8dc63f, #009750);
  left: -10%;
  top: 45%;
  box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
}
.zero:before {
  transform: rotate(45deg) scaleX(0) scaleY(0.7);
  -webkit-animation: cross1 300ms 1s ease-in-out forwards;
  animation: cross1 300ms 1s ease-in-out forwards;
}
.zero:after {
  transform: rotate(-45deg) scaleX(0) scaleY(0.7);
  -webkit-animation: cross2 400ms 1.2s ease-in-out forwards;
  animation: cross2 400ms 1.2s ease-in-out forwards;
}
.zero:nth-child(2):before {
  transform: rotate(45deg) scaleX(0) scaleY(0.7);
  -webkit-animation: cross1 400ms 1.1s ease-in-out forwards;
  animation: cross1 400ms 1.1s ease-in-out forwards;
}
.zero:nth-child(2):after {
  transform: rotate(-45deg) scaleX(0) scaleY(0.7);
  -webkit-animation: cross2 500ms 1.3s ease-in-out forwards;
  animation: cross2 500ms 1.3s ease-in-out forwards;
}

.info {
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0 0.5vmin 1vmin rgba(0, 0, 0, 0.5);
  font-size: 200%;
  padding: 0 24px;
  text-align: center;
}

.link {
  position: absolute;
  bottom: 0;
  padding-bottom: 24px;
}
.link a {
  color: #fff;
  opacity: 0.5;
  text-decoration: none;
  font-weight: 400;
  font-size: 90%;
  transition: opacity 300ms;
}
.link a:hover {
  opacity: 0.9;
}
.instructions {
  background: #fefefe;
  width: 300px;
  min-width: 50vmin;
  height: auto;
  padding: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 0.25rem;
  margin-top: 5vmin;
}
@media (max-width: 650px) {
  .instructions {
    width: 100%;
  }
}
.instructions h2 {
  font-size: 1.25em;
  line-height: 1.3;
  color: #e30528;
}
@media (max-width: 650px) {
  .instructions h2 {
    font-size: 1.05em;
  }
}
.instructions p {
  font-size: 1.15em;
  line-height: 1.5;
  color: #122125;
}
@media (max-width: 650px) {
  .instructions p {
    font-size: 1em;
  }
}
.instructions .step {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1.5rem;
  margin: 0.5rem 0;
}
.instructions .step .icon {
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
}
@media (max-width: 650px) {
  .instructions .step .icon {
    width: 1rem;
    height: 1rem;
  }
}
.instructions .step p {
  display: inline-block;
  width: 80%;
  line-height: 1.5;
  padding-left: 0.5rem;
}

@keyframes bouncy {
  0% {
    transform: translateY(10px) translateX(0) rotate(0);
  }
  25% {
    transform: translateX(-10px) rotate(-10deg);
  }
  50% {
    transform: translateX(0) rotate(0deg);
  }
  75% {
    transform: translateX(10px) rotate(10deg);
  }
  100% {
    transform: translateY(10px) translateX(0) rotate(0);
  }
}
</style>
